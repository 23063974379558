<template>
      <div v-if="ticketData && orderData" class="bg-white mb-3">
        <div class="d-flex justify-content-between align-items-center">
            <div>
                <h1 class="h4 mb-0">Resolve Issue - {{ orderData.patient_name }}</h1>
            </div>
        </div>
    </div>

    <div v-if="ticketData" class="ticket-list mb-4">
        <div class="header-row">
            <div class="row align-items-center">
                <div class="col">
                    <div class="">Issue</div>
                </div>

                <div v-if="ticketData.reason" class="col">
                    <div class="">Reason</div>
                </div>
            </div>
        </div>

        <!--display notes-->
        <div class="content-row">
            <div class="row align-items-center">
                <div class="col">
                     <div >{{ ticketData.type.name }}</div>
                </div>

                <div v-if="ticketData.reason" class="col">
                    <div>{{ ticketData.reason.ticket_reason_name }}</div>
                </div>
            </div>
        </div>

        <br>

        <div v-if="ticketData && ticketData.can_resolve" class="content-row">
            <!-- Form -->
            <form enctype="multipart/form-data" novalidate class="bg-white" @submit.prevent="submitTicket">

                <!-- Options -->
                <div v-if="ticketData.reason && ticketData.reason.options && ticketData.reason.options.length"  class="col-sm-5 col-md-5 mb-2">
                    <DropdownInput
                    label="Please select an option"
                    v-model="dentistOption"
                    :v="v$.dentistOption"
                    :options="ticketData.reason.options.map(option => ({ id: option.id, label: option.preferred ? `${option.name} (preferred)` : option.name }))"
                    />
                </div>

                <div v-if="orderData && ticketData.is_missing_standard" class="col-sm-5 col-md-5 mb-2">
                    <DropdownInput
                        label="Service level"
                        v-model="orderData.standard.id"
                        :v="v$.standard"
                        :options="standards"
                    />
                </div>

                <div v-if="ticketData.is_missing_shade">
                    <div class="col-sm-5 col-md-5 mb-2">
                        <TextInput
                            label="Shade"
                            v-model="shade"
                            :v="v$.shade"
                        />
                    </div>
                </div>

                <div v-if="ticketData && ticketData.type.ask_impression_model_question" class="col-sm-5 col-md-5 mb-2">
                    <DropdownInput
                        label="Will you be sending new impressions/scans?"
                        v-model="newImpression"
                        :v="v$.newImpression"
                        :options="statuses"
                    />
                </div>

                <!-- Files -->
                <div v-if="filesData && filesData.length" style="clear:both;">
                    <div class="row files-row mt-4">
                        <h2 class="h5 mt-3 mb-3 page-section-heading">Uploaded Files</h2>
                        <div v-for="file of filesData" v-bind:key="file.id" class="col-12 col-md-6 col-xl-4 mb-4">
                            <div class="uploaded-file">
                                <a :href="file.url" target="_blank" class="text-truncate"><i class="bi bi-download pe-2"></i> {{ file.file_name }}</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="content-row">
                    <div class="row align-items-center">
                        <div class="col">
                            <div class="text-left">*If you need any more information please contact the lab.</div>
                        </div>
                    </div>
                </div>

                <div class="buttons button-div mt-4">
                    <button class="btn btn-primary me-1" type="submit">Save</button>
                    <button class="btn btn-secondary me-1" type="button" @click="changePage">Cancel</button>
                </div>
            </form>
        </div>
        <div v-else>
            <div class="content-row">
                <div class="row align-items-center">
                    <div class="col">
                        <div class="text-left">This issue cannot be resolved online. Please contact the lab.</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<style scoped lang="scss">
     .ticket-list
    {
        list-style: none;
        padding: 0px;
        margin: 0px;

        > .content-row
        {
            border-top: 1px solid #EEEEEE;
            padding: 10px 0px;

            strong
            {
                color: $swift-dark-grey;
            }
        }

        .header-row
        {
            border-top: 0px;
        }
    }

    .files-row
    {
        border-top: 1px solid #EEEEEE;
        border-bottom: 1px solid #EEEEEE;
        padding: 10px 0px;

        strong
        {
            color: $swift-dark-grey;
        }
    }

    .uploaded-file
    {
        border: $border-width*2 solid $swift-lighter-grey;
        @include border-radius($border-radius);
        padding: calc($spacer / 2) $spacer;

        a
        {
            display: block;
        }
    }
</style>

<script>
    import api from "@/services/api";
    import DropdownInput from '@/components/DropdownInput.vue';
    import TextInput from '@/components/TextInput.vue';
    import useVuelidate from '@vuelidate/core';
    import {required} from '@vuelidate/validators';

    export default {
        props: {
            ticket_id: {
                required: true
            }
        },
        setup () {
            return {
                v$: useVuelidate()
            }
        },
        data() {
            return {
                ticketData: null,
                orderData: null,
                standards: [],
                standard: '',
                shade: '',
                newImpression: '',
                dentistOption: '',
                statuses:[{id:true, label:'Yes'}, {id:false, label:'No'}],
                filesData: null,
            };
            
        },
        validations () {
            return {
                standard: { required },
                shade: {},
                newImpression: {},
                dentistOption: {},
            }
        },
        components: {
            DropdownInput,
            TextInput,
        },
        mounted () {
            this.fetchTicketData();
            this.fetchStandards();
        },
        methods: {            
            fetchTicketData: function() {                
                api
                .get('/api/v1/tickets/' + this.$route.params.ticket_id)
                .then((response) => {
                    this.ticketData = response.data.data;
                    this.fetchOrderData();
                    this.fetchFilesData();
                })
                .catch(() => {
                    console.log('Catch ticket order error');
                });
            },
            fetchOrderData: function() {   
                api
                .get('/api/v1/orders/' + this.ticketData.order_id)
                .then((response) => {
                    this.orderData = response.data.data;
                })
                .catch(() => {
                    console.log('Catch order data error');
                });
            },
            fetchStandards: function() {                
                this.standards = [{
                    id: 1,
                    label: 'NHS / Standard',
                }, {
                    id: 2,
                    label: 'Independent',
                }, {
                    id: 3,
                    label: 'Private',
                }]
            },
            submitTicket: function() {
                // Submit ticket to api
                var data = {
                    'userId': this.$store.state.user.id,
                    'standard': this.standard,
                    'shade': this.shade,
                    'newImpression': this.newImpression,
                    'dentistOption': this.dentistOption,
                };

                api
                .post('/api/v1/tickets/' + this.$route.params.ticket_id + '/resolve', data)
                .then((response) => {
                    this.$router.push({ name: 'Order', params: { 'id': this.orderData.id }});
                    console.log(response);
                })
                .catch(() => {
                    console.log('Catch ticket resolve error');
                });
            },
            fetchFilesData: function() {                
                api
                .get('/api/v1/tickets/' + this.$route.params.ticket_id + '/files')
                .then((response) => {
                    this.filesData = response.data.data;
                })
                .catch(() => {
                    console.log('Catch files error');
                });
            },
            changePage(){
                this.$router.push({ name: 'OrdersIssues' });
            },
        },
    }
</script>